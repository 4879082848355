/* eslint-disable prettier/prettier */
import { AsgardeoSPAClient } from '@asgardeo/auth-spa';

export var auth = AsgardeoSPAClient.getInstance();


export const authConfig = {
  signInRedirectURL: `${process.env.TYPESENSE_WEB_SERVER_PROTOCOL}://${process.env.TYPESENSE_WEB_SERVER_HOST}/index.html`,
  signOutRedirectURL: `${process.env.TYPESENSE_WEB_SERVER_PROTOCOL}://${process.env.TYPESENSE_WEB_SERVER_HOST}/login.html`,
  clientID: `${process.env.ASGARDEO_CLIENT_ID}`,
  baseUrl: `${process.env.ASGARDEO_BASE_URL}`,
  scope: ['openid', 'profile'],
};

auth.initialize(authConfig);

auth.on('sign-out', function (response) {
  sessionStorage.clear();
  window.location.reload();
});

